#about {
	width: 100%;
	padding: 60px;
	font-family:Gilmer Heavy;

	/*background: url(../../../public/img/JoyCrescentBackground.png) center no-repeat;*/
	background: #1a1a1a;
	color: white;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
#about h3 {
	font-size: 22px;
	margin: 20px;
}
#about h2 {
	position: relative;
	margin: 20px;
	margin-top: 100px;
	font-family:Gilmer Heavy;

}
#about h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #1a1a1a 0%, #1a1a1a 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
	top: 30px;
}
#about .about-text li {
	font-family:Gilmer Heavy;
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
#about .about-text li:before {
	content: '\f00c';
	font-family:Gilmer Heavy;
	color: white;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}
#about img {
	width: 450px;
	background: transparent;
	border-right: 0;
	margin: 30px;
}
#about p {
	line-height: 24px;
	margin: 30px;
}
#about .idea {
	margin-top: 10px;
	margin-bottom: 10px;
	font-family:'56th Street Caps';
}
