#services {
	padding: 60px;
	padding-top: 100px;
	padding-bottom: 100px;
	background: #1a1a1a;
}
#services .service-desc {
	margin: 10px 10px 20px;
}
#services h2 {
	color: white;
}
#services .section-title h2::after {
	position: absolute;
	content: "";
	background: rgba(255,255,255,.3);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
#services i.fa {
	font-size: 42px;
	width: 120px;
	height: 120px;
	padding: 40px 0;
	background: linear-gradient(to right, #717071 0%, #3E3A39 100%);
	border-radius: 50%;
	color: #FEFEFE;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
#services h3 {
	font-weight: 500;
	padding: 5px 0;
	color: #FEFEFE;
	font-family:Gilmer Heavy;

}
#services p {
	color: rgba(255,255,255,.75);
	font-family: '56th Street Caps';
}
#services .service-desc {
	margin-bottom: 40px;
}