#menu {
	padding: 15px;
	transition: all 0.8s;
	font-family:Gilmer Heavy;

}
#menu.navbar-default {
	background-color: #C8B568;
	border-color: #1a1a1a;
	box-shadow: 0 0 10px #1a1a1a;

}
#menu a.navbar-brand {
	font-size: 24px;
	font-weight: 700;
	margin-top: 10px;
	color: #1a1a1a;
}
#menu.navbar-default .navbar-nav > li > a {
	color: #1a1a1a;
	font-size: 15px;
	font-weight: 400;
	padding: 8px 2px;
	border-radius: 0;
	margin: 15px 20px 0 20px;
}
#menu.navbar-default .navbar-nav > li > a:after {
	display: block;
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 0;
	height: 2px;
	background: linear-gradient(to right, #1a1a1a 0%, #1a1a1a 100%);
	content: "";
	transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
	width: 100%;
	background-color: transparent;

}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after, .navbar-default .navbar-nav > .active > a:hover:after, .navbar-default .navbar-nav > .active > a:focus:after {
	display: block !important;
	position: absolute !important;
	left: 0 !important;
	bottom: -1px !important;
	width: 100% !important;
	height: 2px !important;
	background: linear-gradient(to right, #1a1a1a 0%, #1a1a1a 100%) !important;
	content: "" !important;
	transition: width 0.2s !important;
}
.navbar-toggle {
	border-radius: 0;
	background: #C8B568;
	border: none;
	margin-top: 15px;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	background-color: #C8B568;
	border-color: #C8B568;
}
.navbar-default .navbar-toggle:hover>.icon-bar {
	background-color: #1a1a1a;
}
.section-title {
	margin-bottom: 70px;

}
.section-title h2 {
	position: relative;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
.section-title h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #1a1a1a 0%, #1a1a1a 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
.section-title p {
	font-size: 18px;
}
.btn-custom {
	color: white;
	/*background-color: #5ca9fb;*/
	background-color: #C8B568;
	background: linear-gradient(to right, #1a1a1a 0%, #1a1a1a 100%);
	padding: 14px 34px;
	letter-spacing: 1px;
	margin: 0;
	font-size: 15px;
	font-weight: 500;
	border-radius: 25px;
	transition: all 0.5s linear;
	border: 0;
	outline: none;
	outline-offset: none;
}
.btn-custom:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
	color: white;
	background-image: none;
	background-color: #1a1a1a;
	outline: none;
	outline-offset: none;
}
.btn:active, .btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
a:focus, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
	outline: none;
	outline-offset: none;
}
.navButton {
	margin: auto;
	width: 100px;
	padding: 10px;
	background:#C8B568;
	margin-top: 13px;
	margin-left: 13px;
	text-align: left;
	outline: 0;
	border: none;
	color: #1a1a1a;

}
.navButton:hover {
	color: #1a1a1a;
	transition: 1s;
}