#features {
	padding: 60px;
	padding-top: 100px;
	padding-bottom: 100px;
	background: #1a1a1a;
	color: white;
	font-family:Gilmer Heavy;
	
}
p {
	font-family: '56th Street Caps'
}
#features i.fa {
	font-size: 38px;
	margin-bottom: 20px;
	transition: all 0.5s;
	color: white;
	width: 100px;
	height: 100px;
	padding: 30px 0;
	border-radius: 50%;
	background: linear-gradient(to right, #717071 0%, #3E3A39 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}