.intro {
	display: table;
	width: 100%;
	height: 100vh;
	padding: 0;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
.intro .overlay {
	background: #1a1a1a;

}
.intro h1 {
	font-family:Gilmer Heavy;
	color: white;
	font-size: 100px;
	font-weight: 700;
	margin-top: -30px;
}
.intro h1 span {
	font-weight: 800;
	color: white;
}
.intro p {
	color: white;
	font-size: 22px;
	font-weight: 300;
	line-height: 30px;
	margin: 0 auto;
	margin-bottom: 60px;
}
header .intro-text {
	padding-top: 350px;
	padding-bottom: 200px;
	text-align: center;
}
header .signInBtn {
	padding: 20px 70px;
	border-radius: 100px;
	background: linear-gradient(to right, rgb(116, 116, 116) , rgb(174, 174, 174));
	color: white;
	text-decoration: none;
}

.subT {
	font-family:'56th Street Caps';
	font-weight: 1000;
	text-transform: uppercase;
}